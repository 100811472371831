import { Theme } from "@mui/material/styles";
import { SxProps, SystemProps } from "@mui/system";
import { ResponsiveStyleValue } from "@mui/system/styleFunctionSx";
import * as CSS from "csstype";

export enum ESearchingType {
  FlightNumber = "flight-number",
  Route = "station",
}
export interface Station {
  cityCode: string;
  countryCode: string;
  stationCode: string;
  macCode: string;
  timeZoneCode: string;
  culture: string;
  name: string;
  shortname: string;
  defaultCurrency?: string;
  enabled?: boolean;
  languageOrder?: {
    "en-us": number;
    "zh-cn": number;
    "zh-hk": number;
    "zh-tw": number;
    "ja-jp": number;
    "ko-kr": number;
  };
  mobileLanguageOrder?: {
    "en-us": number;
    "zh-cn": number;
    "zh-hk": number;
    "zh-tw": number;
    "ja-jp": number;
    "ko-kr": number;
  };
}

export interface DepartureDestination {
  departure: Station;
  destination: Station;
}
export interface DepartureDestinationSelectorProps {
  stations: Station[];
  onChange?: (data: DepartureDestination) => void;
  value?: DepartureDestination;
  name?: string;
}

export interface FlightStatusResponse {
  flight_status: FlightStatusProps[];
}
export interface FlightStatusProps extends SystemProps<Theme> {
  flight_number: string;
  arrival_station: string;
  departure_station: string;
  std: string;
  sta: string;
  etd: string;
  eta: string;
  atd: string;
  ata: string;
  status: string;
}
export interface DepartureDestinationSelectorProps extends SystemProps<Theme> {
  stations: Station[];
  onChange?: (data: DepartureDestination) => void;
  value?: DepartureDestination;
  name?: string;
  color?:
    | ResponsiveStyleValue<CSS.Property.Color | readonly string[] | undefined>
    | ((theme: Theme) => ResponsiveStyleValue<CSS.Property.Color | readonly string[] | undefined>);
  sx?: SxProps<Theme>;
}

export type FlightSearchFrom = {
  departure_date: string;
  flight_number?: string | null;
  departure_station?: string | null;
  arrival_station?: string | null;
};
// export type FlightNumberSearch = {
//     flight_number: string
// }
// export type FlightRouteSearch = {
//     departure_station: string,
//     arrival_station: string
// }
